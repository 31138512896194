import db from "@/plugins/dexie"
import moment from "moment-timezone";
import dadosTabelas from "./local/tabelas"
import apiaberturaloteweb from '@/services/api-recebimentoweb';
import { USER_STORAGE  } from '@/constants/STORAGE'

function getSessionStorage(key, defaultValue) {
    return sessionStorage.getItem(key) ?? defaultValue;
}

export default async function $_atualizaLocal(silent) {
    const info = dadosTabelas();
    for (let i = 0; i < info.length; i += 1) {
        let maisrecente = await db[info[i].nome].orderBy(info[i].campoatt).last();
        if (maisrecente) { 
            // maisrecente = moment.utc(maisrecente[info[i].campoatt]).format('YYYY-MM-DD HH:mm:ss');
            maisrecente = moment.utc(maisrecente[info[i].campoatt]).format('YYYY-MM-DD HH:mm:ss');
            
        } else {
            maisrecente = '01-01-1997 13:00:00';
        }
        let { data } = await apiaberturaloteweb.get(`${info[i].nome}/alterados/${maisrecente}`);
        if (!silent) {
            console.log('%cTabela: ' + info[i].nome, 'font-weight: bold; border-top: 1px solid #fff; padding-top: 3px')
            const color = (data.length > 0) ? '#f65' : '#5cc'; 
            console.log('%c' + data.length + '%c itens para criação ou atualização', 'color: ' + color, 'color: #fff');
        } else if (data.length > 0) {
            console.log('%cTabela: ' + info[i].nome, 'font-weight: bold; border-top: 1px solid #fff; padding-top: 3px')
            const color = (data.length > 0) ? '#f65' : '#5cc'; 
            console.log('%c' + data.length + '%c itens para criação ou atualização', 'color: ' + color, 'color: #fff');
        }
        if (!data) { data = []; }
        $_manipulaTabela(data, info[i]);
    }
}

async function $_deletaLocal(idnuvemlocal, tabela) {
    let { data } = await apiaberturaloteweb.get(`${tabela.nome}/idnuvem`)
    data = await data.map((item) => item.id);


    idnuvemlocal.forEach((itemlocal) => {
        if (!data.includes(itemlocal)) {
            setTimeout(() => {
                console.log(`%cTabela: ${tabela.nome} | ${itemlocal} deletado!`, 'font-weight: bold; border-top: 1px solid #fff; padding-top: 3px');
            }, 100); 
            db[tabela.nome].where({ [`${tabela.prefixo}idnuvem`]: itemlocal }).delete();
        }
    })

}

async function $_manipulaTabela(data, tabela) {
    let idnuvem = await db[tabela.nome].toArray();
    idnuvem = await idnuvem.map((item) => {
        return item[`${tabela.prefixo}idnuvem`];
    })

    $_deletaLocal(idnuvem, tabela);

    if (!idnuvem) { idnuvem = []; }
    data.forEach((item) => {
        const itemFormatado = tabela.model(item);
        if (idnuvem.includes(item.id)) {
            db[tabela.nome].where({ [`${tabela.prefixo}idnuvem`]: item.id }).modify(itemFormatado);
        } else {
            db[tabela.nome].add(itemFormatado);
        }
    });
}

export async function $_enviaApontamento() {
    let apontamentos = await db.apontamentoproducao.toArray();
    const idusuario = JSON.parse(await getSessionStorage(USER_STORAGE.useidusuario , ''));
    apontamentos.forEach((item) => {
        if (!item.appdataalteracao) {
            if (item.appidnuvem) {
                const payload = {
                    appidordemproducao: item.appidordemproducao,
                    appidaberturaloteembalagem: item.appidaberturaloteembalagem,
                    appquantidadeembalagem: item.appquantidadeembalagem,
                    appdatainicio:  moment(item.appdatainicio),
                    appidlocalarmazenamento: item.appidlocalarmazenamento,
                    appidaberturalote: item.appidaberturalote,
                    appdatatermino: moment(item.appdatatermino),
                    appidturno: item.appidturno,
                    appquantidade: item.appquantidade,
                    appidusuario: idusuario ,
                    appdataalteracao: moment(),
                    id: item.appidnuvem,
                }
                apiaberturaloteweb.put(`/apontamentoproducao/${item.appidnuvem}`, payload).then((res) => {
                    const alteracao = (moment.utc(res.data.appdataalteracao).subtract(3, 'hours').format('YYYY-MM-DDTHH:mm:ss'))
                    db.apontamentoproducao.update(item.id, {
                        appdataalteracao: alteracao,
                    });
                });    
            } else {
                const payload = {
                    appidordemproducao: item.appidordemproducao,
                    appidaberturaloteembalagem: item.appidaberturaloteembalagem,
                    appquantidadeembalagem: item.appquantidadeembalagem,
                    appdatainicio:  moment(item.appdatainicio),
                    appidlocalarmazenamento: item.appidlocalarmazenamento,
                    appidaberturalote: item.appidaberturalote,
                    appdatatermino: moment(item.appdatatermino),
                    appidturno: item.appidturno,
                    appquantidade: item.appquantidade,
                    appidusuario: idusuario ,
                    appdataalteracao: moment(),
                }
                apiaberturaloteweb.post('/apontamentoproducao', payload).then((res) => {
                    const alteracao = (moment.utc(res.data.appdataalteracao).subtract(3, 'hours').format('YYYY-MM-DDTHH:mm:ss'))
                    db.apontamentoproducao.update(item.id, {
                        appidusuario: res.data.appidusuario,
                        appidnuvem: res.data.id,
                        appdataalteracao: alteracao,
                    });
                });
            }
        }
    }); 
}

export async function SetupEnvioLocal({ silent = false } = {}) {
    if (navigator.onLine) {
        $_atualizaLocal(silent);
        $_enviaApontamento();
    }
    // window.addEventListener('offline', () => {
    //     console.log('Atualmente Offline');
    // });
    // window.addEventListener('online', () => {
    //     console.log('Atualmente Online');
    //     $_atualizaLocal();
    //     $_enviaApontamento();
    // });
}
