import apimodulosweb from '@/services/api-recebimentoweb';
import * as STORAGE from '@/constants/STORAGE';
import storage from '@/services/storage';
import CryptoJS from 'crypto-js';

let findTimeout = null;

export default {
  namespaced: true,

  state: {
    list: [],
  },

  getters: {
    list: (state) => state.list,
  },

  // NUNCA ALTERAR state DIRETAMENTE EM ACTIONS
  actions: {
    async find(_, {
      where, ...params
    } = {}) {
      const [{ data }, { data: { count: total } }] = await Promise.all([
        apimodulosweb.get('modulos', { params: { ...where, ...params } }),
        apimodulosweb.get('modulos/count', { params: where }),
      ]);

      return { data, total };
    },

    // CONSULTA COM CONTROLE DE CACHE
    async findWithCache({ commit, state }, { force, ttl } = {}) {
      if (!force && state.list?.length > 0) {
        return;
      }

      clearTimeout(findTimeout);

      const { data } = await apimodulosweb.get('modulos');

      commit('SET_LIST', data);

      if (ttl === Infinity) return;

      findTimeout = setTimeout(() => {
        commit('SET_LIST', null);
      }, ttl ?? 1000 * 60); // TTL -> Time To Live
    },

    // CONSULTA SEM CACHE
    async findById(_, { id }) {
      const { data } = await apimodulosweb.get(`modulos/${id}`);
      return data;
    },

    async findByAll(_, { objModulo }) {
      const { data } = await apimodulosweb.get(`modulos
      /${objModulo.moddescmodulo}
      /${objModulo.moddescprograma}
      /${objModulo.moddescrotina}
      `);
      return data;
    },

    async metodoAcesso(_, {
      nomeDescricao, nomeRotina, nomePrograma, nomeModulo,
    }) {
      const moduloJSON = {
        moddescmodulo: nomeModulo,
        moddescprograma: nomePrograma,
        moddescrotina: nomeRotina,
        moddescricao: nomeDescricao,
      };

      let userId = JSON.parse(await storage.getItem(STORAGE.USER_STORAGE.userId));
      const mult = (userId.toString()).slice(0, 3);
      userId = (userId.toString()).slice(3, 99);
      userId = ((userId - 1) / 11) - mult;
      const middleHash = userId + 74;

      // TRATANDO AS INFORMAÇÕES DO MODULO

      /*

      const moduloCut = [];
      if (!moduloJSON.moddescmodulo) {
        const rota = window.location.pathname;
        for (let i = 1; i < rota.length; i += 1) {
          if (rota[i] === '/' && moduloCut.length < 1) {
            moduloCut.push(i);
          }
        }
        // Trata casos em que o modulo não é convencional
        moduloJSON.moddescmodulo = (rota.slice(1, moduloCut[0])).toUpperCase();
        if (!moduloJSON.moddescmodulo || moduloJSON.moddescmodulo === '') {
          moduloJSON.moddescmodulo = 'HOME';
        }
      }
      let programa = moduloJSON.moddescprograma;
      if (programa.includes('View') || programa.includes('Default') || programa.includes('App')) {
        programa = programa.replace('View', '');
        programa = programa.replace('Default', '');
        programa = programa.replace('App', '');
        moduloJSON.moddescprograma = programa.toUpperCase();
      }
      // Trata casos em que o nome do programa é o mesmo do modulo.
      if (moduloJSON.moddescprograma === moduloJSON.moddescmodulo) {
        switch (moduloJSON.moddescmodulo) {
          case 'IMAGENS': moduloJSON.moddescprograma = 'SELECAO_DE_DOCUMENTO';
            break;
          case 'HOME': moduloJSON.moddescprograma = 'TELA_INICIAL';
            break;
          default: moduloJSON.moddescprograma = 'CONSULTA_CARDS';
        }
      }

      */

      // VERIFICA SE O MODULO JÁ EXISTE NO STORAGE
      let existe = false;
      let storageString = JSON.stringify(await storage.getItem(STORAGE.MODULOS_STORAGE.modulos));
      storageString = storageString.replace('"', '');
      const storageArray = storageString.split('|');
      const RotaMiddleHash = `${moduloJSON.moddescmodulo}/${moduloJSON.moddescprograma}/${moduloJSON.moddescrotina}/${middleHash}`;
      const HashRota = CryptoJS.SHA256(RotaMiddleHash).toString(CryptoJS.enc.Hex);
      for (let i = 0; i < storageArray.length; i += 1) {
        if (storageArray[i] === HashRota) {
          existe = true;
          break;
        }
      }

      // TRATANDO RETORNO
      let bloqueio;
      if (!existe) {
        const { data } = await apimodulosweb.get(`modulos/${moduloJSON.moddescmodulo}/${moduloJSON.moddescprograma}/${moduloJSON.moddescrotina}/${userId}`);
        if (!data) {
          await apimodulosweb.post('/modulos', moduloJSON);
          bloqueio = true;
        } else if (data.peridusuario === userId) {
          if (storageString === 'null') {
            storageString = '';
          }
          storageString = storageString.replace('"', '');
          const novoStorage = `${storageString}${HashRota}|`;
          storage.setItem(STORAGE.MODULOS_STORAGE.modulos, (`${novoStorage}`));
          bloqueio = false;
        } else {
          bloqueio = true;
        }
        return bloqueio;
      }
      bloqueio = false;
      return bloqueio;

      // RETORNA UMA SERIE DE BOOLEANS, TRUE É BLOQUEADO.
    },
  },

  // APENAS O MUTATION FAZ ALTERAÇÕES NO STATE
  mutations: {
    SET_LIST(state, list) {
      state.list = list ?? [];
    },
  },
};
